* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
.body{
    background: rgb(148, 148, 148);
}
.login-page {
    height: 100vh;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}
.form {
  position: relative;
  border-radius: 5px;
  width: 360px;
  background-color: #ffffff;
  padding:40px;
}
.form input {
    outline: 0;
    background: #f2f2f2;
    border-radius: 4px;
    width: 100%;
    border: 0;
    margin: 15px 0;
    padding: 15px;
    font-size: 14px;
}
.form input:focus {
    box-shadow: 0 0 5px 0 rgb(0, 58, 13);
}

span {
    color: red;
    margin: 10px 0;
    font-size: 14px;
}

.form button {
    outline: 0;
    background: green;
    width: 100%;
    border: 0;
    margin-top: 10px;
    border-radius: 3px;
    padding: 15px;
    color: #FFFFFF;
    font-size: 15px;
    -webkit-transition: all 0.3 ease;
    transition: all 0.4s ease-in-out;
    cursor: pointer;
}
.form button:hover,
.form button:active,
.form button:focus {
    background: black;
    color: #fff;
}
.message{
    margin: 15px 0;
    text-align: center;
}
.form .message a {
    font-size: 14px;
    color: green;
    text-decoration: none;
}